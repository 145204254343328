import Link from 'next/link';

import type { SectionWithProducts, SubSection } from '@/constants/index';
import { hasProducts } from '@/constants/index';
import { SonyChevronRightDoubleIcon, SonyChevronRightIcon, SonyExternalLinkIcon } from '@/icons';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';

import styles from './SectionItem.module.scss';

interface SubSectionItemProps {
    className: string;
    section: SubSection;
    onSelect: (section: SectionWithProducts) => void;
}

export function SubSectionItem({ className, section, onSelect }: SubSectionItemProps) {
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    function handleClick() {
        if (hasProducts(section)) {
            onSelect(section);

            fireComponentInteractionEvent({
                interactionType: 'menu-item-click',
                componentType: 'presscentre_header',
                componentID: 'seu-press-centre-main-header',
                actionableItemValue: section.title,
            });
        } else {
            fireComponentInteractionEvent({
                interactionType: 'navigation-click',
                componentType: 'presscentre_header',
                componentID: 'seu-press-centre-main-header',
                actionableItemValue: section.title,
            });
        }
    }

    if (hasProducts(section)) {
        return (
            <button className={className} onClick={handleClick}>
                {section.title}
                <SonyChevronRightDoubleIcon />
            </button>
        );
    }

    return (
        <Link
            className={className}
            href={section.link}
            target={section.isExternal ? '_blank' : undefined}
            rel={section.isExternal ? 'noopener noreferrer' : undefined}
            onClick={handleClick}
        >
            {section.title}
            {section.isExternal && <SonyExternalLinkIcon className={styles.externalLinkIcon} />}
            <SonyChevronRightIcon />
        </Link>
    );
}
