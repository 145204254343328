import { useCurrentLocale } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import Link from 'next/link';

import { useFooterContext } from '@/contexts/footer';
import { SonyEvent, useSonyAnalytics } from '@/modules/SonyAnalytics';
import { getAbsoluteSonyUrl } from '@/utils';

import styles from './SecondarySection.module.scss';

export function SecondarySection() {
    const { secondaryContent } = useFooterContext();
    const locale = useCurrentLocale();
    const { fireEvent, fireComponentInteractionEvent } = useSonyAnalytics();

    if (!secondaryContent) {
        return null;
    }

    const { regionSelector, links, socialLinks } = secondaryContent;

    return (
        <div className={classNames('container', styles.container)}>
            <div className={styles.regionSelector}>
                <Link
                    className={styles.regionSelectorLink}
                    href={getAbsoluteSonyUrl(regionSelector.link, locale)}
                    onClick={() =>
                        fireComponentInteractionEvent({
                            interactionType: 'navigation-click',
                            componentType: 'presscentre_footer',
                            componentID: 'seu-press-centre-footer',
                            actionableItemValue: regionSelector.currentLocale,
                        })
                    }
                >
                    {regionSelector.currentLocale}
                </Link>
            </div>
            <div className={styles.nav}>
                <ul className={styles.links}>
                    {links.map((link) => (
                        <li key={link.title}>
                            <Link
                                className={styles.link}
                                href={getAbsoluteSonyUrl(link.link, locale)}
                                onClick={() =>
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_footer',
                                        componentID: 'seu-press-centre-footer',
                                        actionableItemValue: link.title,
                                    })
                                }
                            >
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
                <ul className={styles.socialLinks}>
                    {socialLinks.map((link) => (
                        <li key={link.title}>
                            <Link
                                className={styles.socialLink}
                                href={getAbsoluteSonyUrl(link.link, locale)}
                                aria-label={link.title}
                                title={link.title}
                                onClick={() => {
                                    fireEvent(SonyEvent.CLICKED_SOCIAL_MEDIA, {
                                        platform: link.networkName,
                                    });
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_footer',
                                        componentID: 'seu-press-centre-footer',
                                        actionableItemValue: link.title,
                                    });
                                }}
                            >
                                {/* eslint-disable-next-line @next/next/no-img-element */}
                                <img
                                    className={styles.socialIcon}
                                    src={getAbsoluteSonyUrl(link.iconUrl, locale)}
                                    alt={link.networkName}
                                />
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
