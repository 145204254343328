import { Tracking as PageTracking } from '@prezly/analytics-nextjs';
import type { Notification } from '@prezly/sdk';
import { PageSeo, useNewsroom, useNewsroomContext } from '@prezly/theme-kit-nextjs';
import { scrollbarWidth } from '@xobotyi/scrollbar-width';
import classNames from 'classnames';
import Head from 'next/head';
import { Router, useRouter } from 'next/router';
import type { PropsWithChildren } from 'react';
import { useEffect, useMemo, useState } from 'react';

import { NotificationsBar } from '@/components';
import { LoadingBar, ScrollToTopButton } from '@/ui';

import Branding from './Branding';
import Header from './Header';
import { SonyFooter } from './SonyFooter';
import SubscribeForm from './SubscribeForm';

import styles from './Layout.module.scss';

interface Props {
    className?: string;
    description?: string;
    hasError?: boolean;
    imageUrl?: string;
    title?: string;
}

const noIndex = process.env.VERCEL === '1';

function Layout({
    children,
    className,
    description,
    hasError,
    imageUrl,
    title,
}: PropsWithChildren<Props>) {
    const [isLoadingPage, setIsLoadingPage] = useState(false);
    const newsroom = useNewsroom();
    const { notifications } = useNewsroomContext();
    const { pathname } = useRouter();

    useEffect(() => {
        function onRouteChangeStart() {
            setIsLoadingPage(true);
        }

        function routeChangeComplete() {
            setIsLoadingPage(false);
        }

        Router.events.on('routeChangeStart', onRouteChangeStart);
        Router.events.on('routeChangeComplete', routeChangeComplete);
        return () => {
            Router.events.off('routeChangeStart', onRouteChangeStart);
            Router.events.off('routeChangeComplete', routeChangeComplete);
        };
    }, []);

    const displayedNotifications = useMemo(() => {
        if (pathname === '/s/[uuid]') {
            return [
                ...notifications,
                {
                    id: 'preview-warning',
                    type: 'preview-warning',
                    style: 'warning',
                    title: 'This is a preview with a temporary URL which will change after publishing.',
                    description: '',
                    actions: [],
                } as Notification,
            ];
        }

        return notifications;
    }, [notifications, pathname]);

    return (
        <>
            <Head>
                <style
                    dangerouslySetInnerHTML={{
                        __html: `:root { --scrollbar-width: ${scrollbarWidth()}px }`,
                    }}
                />
            </Head>
            <PageTracking />
            <Branding newsroom={newsroom} />
            <PageSeo
                title={title}
                description={description}
                imageUrl={imageUrl}
                noindex={noIndex}
                nofollow={noIndex}
            />
            <NotificationsBar notifications={displayedNotifications} />
            <div className={styles.layout}>
                <Header hasError={hasError} />
                <main
                    className={classNames(styles.content, className, {
                        [styles.noTopBottomPadding]: pathname === '/',
                        [styles.noTopPadding]: pathname.toLowerCase().startsWith('/category/'),
                    })}
                >
                    {children}
                    <LoadingBar isLoading={isLoadingPage} />
                </main>
                <SubscribeForm />
                <SonyFooter />
            </div>
            <ScrollToTopButton />
        </>
    );
}

export default Layout;
