import { Popover } from '@headlessui/react';
import { translations } from '@prezly/theme-kit-intl';
import classNames from 'classnames';
import type { AnimationDefinition } from 'framer-motion';
import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import {
    hasProducts,
    hasSubSections,
    type SectionWithProducts,
    type SectionWithSubSection,
} from '@/constants';
import { useHeaderContext } from '@/contexts/header';
import { useDevice } from '@/hooks/useDevice';
import {
    SonyChevronRightDoubleIcon,
    SonyChevronRightIcon,
    SonyCloseIcon,
    SonyMenuIcon,
} from '@/icons';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';
import { Button } from '@/ui';

import { ProductsPanel, ScrollLock, SectionItem, SubSectionItem } from './components';

import styles from './ProductNavigation.module.scss';

interface Props {
    buttonClassName?: string;
}

export function ProductNavigation({ buttonClassName }: Props) {
    const { productSections } = useHeaderContext();
    const { formatMessage } = useIntl();
    const { isTablet } = useDevice();
    const [selectedSection, setSelectedSection] = useState<SectionWithSubSection | null>(null);
    const [selectedSubSection, setSelectedSubSection] = useState<SectionWithProducts | null>(null);
    const mainListRef = useRef<HTMLUListElement>(null);
    const selectedSectionRef = useRef<HTMLUListElement>(null);
    const selectedSubSectionRef = useRef<HTMLUListElement>(null);
    const [initialListHeight, setInitialListHeight] = useState(0);
    const [listHeight, setListHeight] = useState(0);
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    function handleSectionAnimationStart(definition: AnimationDefinition) {
        const isTransitioningToSubSection =
            typeof definition === 'object' &&
            'translateX' in definition &&
            definition.translateX === 0;

        setListHeight(
            isTransitioningToSubSection
                ? selectedSectionRef.current?.offsetHeight ?? initialListHeight
                : initialListHeight,
        );
    }

    function handleSubSectionAnimationStart(definition: AnimationDefinition) {
        const isTransitioningToProductsList =
            typeof definition === 'object' &&
            'translateX' in definition &&
            definition.translateX === 0;

        setListHeight(
            (isTransitioningToProductsList
                ? selectedSubSectionRef.current?.offsetHeight
                : selectedSectionRef.current?.offsetHeight) ?? initialListHeight,
        );
    }

    // Since we can't listen to `Popover` open/close state, we use backdrop animation to clear selected section when menu is closed
    function handleMenuAnimationStart(definition: AnimationDefinition) {
        const isClosingMenu =
            typeof definition === 'object' && 'opacity' in definition && definition.opacity === 0;

        if (isClosingMenu) {
            setTimeout(() => setSelectedSection(null), 300);
        }
    }

    // Initialize list height, so that we can recall it when animating back to the main list
    useEffect(() => {
        setInitialListHeight(mainListRef.current?.offsetHeight || 0);
        setListHeight(mainListRef.current?.offsetHeight || 0);
    }, []);

    // Clear selected sub-section when switching between main sections
    useEffect(() => {
        setSelectedSubSection(null);
    }, [selectedSection]);

    return (
        <Popover className={styles.container}>
            {({ open }) => (
                <>
                    {open && isTablet && <ScrollLock />}

                    <Popover.Overlay
                        as={motion.div}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: open ? 1 : 0 }}
                        style={{ pointerEvents: open ? 'auto' : 'none' }}
                        static
                        className={styles.backdrop}
                        onAnimationStart={handleMenuAnimationStart}
                    />

                    <Popover.Button
                        as={Button}
                        className={classNames(buttonClassName, {
                            [styles.toggleActive]: open,
                        })}
                        variation="navigation"
                        icon={open ? SonyCloseIcon : SonyMenuIcon}
                        aria-label={formatMessage(translations.misc.toggleMobileNavigation)}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'in-page-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-main-header',
                                actionableItemValue: formatMessage(
                                    translations.misc.toggleMobileNavigation,
                                ),
                            })
                        }
                    />

                    <Popover.Panel
                        as={motion.div}
                        initial={{ height: 0 }}
                        animate={{
                            height: open ? listHeight : 0,
                        }}
                        transition={{ ease: 'easeInOut', duration: 0.2 }}
                        className={classNames(styles.panel, {
                            [styles.withProducts]: selectedSubSection,
                        })}
                        style={{ pointerEvents: open ? 'auto' : 'none' }}
                        static
                    >
                        <ul ref={mainListRef} className={styles.list}>
                            {productSections.map((section) => (
                                <li className={styles.listItem} key={section.title}>
                                    <SectionItem
                                        className={styles.listItemButton}
                                        onSelect={setSelectedSection}
                                        section={section}
                                    />
                                    <AnimatePresence>
                                        {hasSubSections(section) && section === selectedSection && (
                                            <motion.ul
                                                className={classNames(
                                                    styles.list,
                                                    styles.secondLevelList,
                                                )}
                                                ref={selectedSectionRef}
                                                initial={{
                                                    translateX: '100%',
                                                }}
                                                animate={{ translateX: 0 }}
                                                exit={{ translateX: '100%' }}
                                                transition={{ ease: 'easeInOut', duration: 0.2 }}
                                                onAnimationStart={handleSectionAnimationStart}
                                            >
                                                <li className={styles.listItem}>
                                                    <button
                                                        className={classNames(
                                                            styles.listItemButton,
                                                            styles.backButton,
                                                        )}
                                                        onClick={() => setSelectedSection(null)}
                                                    >
                                                        <SonyChevronRightDoubleIcon
                                                            className={styles.chevronInverse}
                                                        />
                                                        {selectedSection.title}
                                                    </button>
                                                </li>
                                                {selectedSection.items.map((item) => (
                                                    <li
                                                        className={styles.listItem}
                                                        key={item.title}
                                                    >
                                                        <SubSectionItem
                                                            className={classNames(
                                                                styles.listItemButton,
                                                                {
                                                                    [styles.active]:
                                                                        selectedSubSection === item,
                                                                },
                                                            )}
                                                            onSelect={setSelectedSubSection}
                                                            section={item}
                                                        />
                                                        <AnimatePresence>
                                                            {hasProducts(item) &&
                                                                selectedSubSection === item &&
                                                                (isTablet ? (
                                                                    <motion.ul
                                                                        ref={selectedSubSectionRef}
                                                                        className={classNames(
                                                                            styles.list,
                                                                            styles.secondLevelList,
                                                                        )}
                                                                        initial={{
                                                                            translateX: '100%',
                                                                        }}
                                                                        animate={{ translateX: 0 }}
                                                                        exit={{
                                                                            translateX: '100%',
                                                                        }}
                                                                        transition={{
                                                                            ease: 'easeInOut',
                                                                            duration: 0.2,
                                                                        }}
                                                                        onAnimationStart={
                                                                            handleSubSectionAnimationStart
                                                                        }
                                                                    >
                                                                        <li
                                                                            className={
                                                                                styles.listItem
                                                                            }
                                                                        >
                                                                            <button
                                                                                className={classNames(
                                                                                    styles.listItemButton,
                                                                                    styles.backButton,
                                                                                )}
                                                                                onClick={() =>
                                                                                    setSelectedSubSection(
                                                                                        null,
                                                                                    )
                                                                                }
                                                                            >
                                                                                <SonyChevronRightDoubleIcon
                                                                                    className={
                                                                                        styles.chevronInverse
                                                                                    }
                                                                                />
                                                                                {
                                                                                    selectedSubSection.title
                                                                                }
                                                                            </button>
                                                                        </li>
                                                                        {selectedSubSection.items.map(
                                                                            (product) => (
                                                                                <li
                                                                                    className={
                                                                                        styles.listItem
                                                                                    }
                                                                                    key={
                                                                                        product.title
                                                                                    }
                                                                                >
                                                                                    <Link
                                                                                        className={
                                                                                            styles.listItemButton
                                                                                        }
                                                                                        href={
                                                                                            product.link
                                                                                        }
                                                                                    >
                                                                                        {
                                                                                            product.title
                                                                                        }
                                                                                        <SonyChevronRightIcon />
                                                                                    </Link>
                                                                                </li>
                                                                            ),
                                                                        )}
                                                                    </motion.ul>
                                                                ) : (
                                                                    <ProductsPanel
                                                                        section={selectedSubSection}
                                                                    />
                                                                ))}
                                                        </AnimatePresence>
                                                    </li>
                                                ))}
                                            </motion.ul>
                                        )}
                                    </AnimatePresence>
                                </li>
                            ))}
                        </ul>
                    </Popover.Panel>
                </>
            )}
        </Popover>
    );
}
