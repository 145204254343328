import { getLanguageDisplayName, LocaleObject } from '@prezly/theme-kit-core';
import {
    useCurrentLocale,
    useCurrentStory,
    useGetLinkLocaleSlug,
    useGetTranslationUrl,
    useLanguages,
} from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import { useMemo } from 'react';

import { Dropdown } from '@/components';
import { useDisplayedLanguages } from '@/hooks';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';

import styles from './LanguagesDropdown.module.scss';

type Props = {
    hasError?: boolean;
};

function LanguagesDropdown({ hasError }: Props) {
    const currentLocale = useCurrentLocale();
    const languages = useLanguages();
    const getTranslationUrl = useGetTranslationUrl();
    const currentStory = useCurrentStory();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    const currentLanguage = useMemo(
        () => languages.find((language) => language.code === currentLocale.toUnderscoreCode()),
        [currentLocale, languages],
    );

    const displayedLanguages = useDisplayedLanguages();

    // Don't show language selector if there are no other locale to choose
    if (!currentLanguage || displayedLanguages.length <= 1) {
        return null;
    }

    const currentLanguageLabel = LocaleObject.fromAnyCode(currentLanguage.code)
        .toNeutralLanguageCode()
        .toUpperCase();

    return (
        <Dropdown buttonClassName={styles.button} label={currentLanguageLabel}>
            {displayedLanguages.map((language) => {
                const locale = LocaleObject.fromAnyCode(language.code);
                const translationLink = hasError ? '/' : getTranslationUrl(locale);

                return (
                    <Dropdown.Item
                        key={locale.toHyphenCode()}
                        href={translationLink}
                        localeCode={
                            currentStory && translationLink !== '/'
                                ? false
                                : getLinkLocaleSlug(locale)
                        }
                        forceRefresh
                        className={classNames(styles.item, {
                            [styles.disabled]: language.code === currentLocale.toUnderscoreCode(),
                        })}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-secondary-header',
                                actionableItemValue: getLanguageDisplayName(language, languages),
                            })
                        }
                    >
                        {getLanguageDisplayName(language, languages)}
                    </Dropdown.Item>
                );
            })}
        </Dropdown>
    );
}

export default LanguagesDropdown;
