import { MainSection } from './MainSection';
import { MetaSection } from './MetaSection';
import { SecondarySection } from './SecondarySection';

import styles from './SonyFooter.module.scss';

export function SonyFooter() {
    return (
        <footer className={styles.container}>
            <MainSection />
            <SecondarySection />
            <MetaSection />
        </footer>
    );
}
