import { useEffect } from 'react';

export function ScrollLock() {
    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = '';
        };
    }, []);

    return null;
}
