import { useGetLinkLocaleSlug } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { FormattedMessage, useIntl } from 'react-intl';

import { Dropdown } from '@/components';
import { useDevice } from '@/hooks/useDevice';
import { CUSTOM_MESSAGES } from '@/intl';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';
import { ButtonLink } from '@/ui';

import styles from './Navigation.module.scss';

const NAV_ITEMS = [
    {
        path: '/news',
        messageDescriptor: CUSTOM_MESSAGES.navigation.news,
    },
    {
        path: '/media',
        messageDescriptor: CUSTOM_MESSAGES.media.title,
    },
    {
        path: '/contacts',
        messageDescriptor: CUSTOM_MESSAGES.navigation.contacts,
    },
];

export function Navigation() {
    const { isMobile } = useDevice();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();
    const { asPath } = useRouter();
    const { formatMessage } = useIntl();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    if (isMobile) {
        return (
            <Dropdown
                buttonClassName={styles.button}
                label={<FormattedMessage {...NAV_ITEMS[0].messageDescriptor} />}
            >
                {NAV_ITEMS.map(({ path, messageDescriptor }) => (
                    <Dropdown.Item
                        key={path}
                        href={path}
                        localeCode={getLinkLocaleSlug()}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-secondary-header',
                                actionableItemValue: formatMessage(messageDescriptor),
                            })
                        }
                    >
                        <FormattedMessage {...messageDescriptor} />
                    </Dropdown.Item>
                ))}
            </Dropdown>
        );
    }

    return (
        <ul className={styles.navigation}>
            {NAV_ITEMS.map(({ path, messageDescriptor }) => (
                <li key={path} className={styles.navigationItem}>
                    <ButtonLink
                        href={path}
                        localeCode={getLinkLocaleSlug()}
                        variation="navigation"
                        className={classNames(styles.button, {
                            [styles.active]: asPath.startsWith(path),
                        })}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-secondary-header',
                                actionableItemValue: formatMessage(messageDescriptor),
                            })
                        }
                    >
                        <FormattedMessage {...messageDescriptor} />
                    </ButtonLink>
                </li>
            ))}
        </ul>
    );
}
