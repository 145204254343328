import { Disclosure } from '@headlessui/react';
import { useCurrentLocale } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import { motion } from 'framer-motion';
import Link from 'next/link';

import type { MainSection as MainSectionType } from '@/constants';
import { isMainSectionWithSearch, SITE_URL_BY_LOCALE } from '@/constants';
import { useFooterContext } from '@/contexts/footer';
import { useDevice } from '@/hooks/useDevice';
import { IconGlobe } from '@/icons';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';

import styles from './MainSection.module.scss';

function MainSectionContent({ section }: { section: MainSectionType }) {
    const { fireComponentInteractionEvent } = useSonyAnalytics();
    const currentLocale = useCurrentLocale();

    const sonySiteUrl =
        SITE_URL_BY_LOCALE[currentLocale.toHyphenCode()] || SITE_URL_BY_LOCALE['en-UK'];

    function makeSonyUrl(path: string) {
        if (path.startsWith('http')) {
            return path;
        }

        const url = new URL(sonySiteUrl);
        url.pathname = path;
        return url.toString();
    }

    if (isMainSectionWithSearch(section)) {
        const { form } = section;

        return (
            <div className={styles.content}>
                <form action={makeSonyUrl(form.action)} className={styles.form} method="GET">
                    <input
                        className={styles.input}
                        type="text"
                        aria-label={form.label}
                        placeholder={form.label}
                    />
                    <IconGlobe className={styles.globeIcon} />
                    <button
                        className={styles.submit}
                        type="submit"
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_footer',
                                componentID: 'seu-press-centre-footer',
                                actionableItemValue: form.submitText,
                            })
                        }
                    >
                        {form.submitText}
                    </button>
                </form>
                <ul>
                    {section.links.map((link) => (
                        <li key={link.title}>
                            <Link
                                href={link.link}
                                className={styles.link}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() =>
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_footer',
                                        componentID: 'seu-press-centre-footer',
                                        actionableItemValue: link.title,
                                    })
                                }
                            >
                                {link.title}
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        );
    }

    return (
        <div className={styles.content}>
            <ul>
                {section.links.map((link) => (
                    <li key={link.title}>
                        <Link
                            href={link.link}
                            className={styles.link}
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() =>
                                fireComponentInteractionEvent({
                                    interactionType: 'navigation-click',
                                    componentType: 'presscentre_footer',
                                    componentID: 'seu-press-centre-footer',
                                    actionableItemValue: link.title,
                                })
                            }
                        >
                            {link.title}
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export function MainSection() {
    const { mainSections } = useFooterContext();
    const { isTablet } = useDevice();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    if (!mainSections || !mainSections.length) {
        return null;
    }

    if (isTablet) {
        return (
            <div>
                {mainSections.map((section) => (
                    <Disclosure key={section.title}>
                        {({ open }) => (
                            <div className={classNames(styles.disclosure, { [styles.open]: open })}>
                                <Disclosure.Button
                                    className={classNames(styles.disclosureButton, {
                                        [styles.open]: open,
                                    })}
                                    onClick={() =>
                                        fireComponentInteractionEvent({
                                            interactionType: 'in-page-click',
                                            componentType: 'presscentre_footer',
                                            componentID: 'seu-press-centre-footer',
                                            actionableItemValue: section.title,
                                        })
                                    }
                                >
                                    {section.title}
                                </Disclosure.Button>
                                <Disclosure.Panel
                                    as={motion.div}
                                    className={styles.panel}
                                    initial={{ height: 0 }}
                                    animate={{
                                        height: open ? 'auto' : 0,
                                    }}
                                    static
                                    style={{ pointerEvents: open ? 'auto' : 'none' }}
                                >
                                    <MainSectionContent section={section} />
                                </Disclosure.Panel>
                            </div>
                        )}
                    </Disclosure>
                ))}
            </div>
        );
    }

    return (
        <div className={classNames('container', styles.container)}>
            {mainSections.map((section) => (
                <div key={section.title}>
                    <h3 className={styles.title}>{section.title}</h3>
                    <MainSectionContent section={section} />
                </div>
            ))}
        </div>
    );
}
