import { motion } from 'framer-motion';
import Link from 'next/link';

import type { SectionWithProducts } from '@/constants/index';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';

import styles from './ProductsPanel.module.scss';

interface Props {
    section: SectionWithProducts;
}

export function ProductsPanel({ section }: Props) {
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    return (
        <motion.div className={styles.container}>
            <ul className={styles.list}>
                {section.items.map((product) => (
                    <li className={styles.item} key={product.title}>
                        <Link
                            className={styles.link}
                            href={product.link}
                            onClick={() =>
                                fireComponentInteractionEvent({
                                    interactionType: 'navigation-click',
                                    componentType: 'presscentre_header',
                                    componentID: 'seu-press-centre-main-header',
                                    actionableItemValue: product.title,
                                })
                            }
                        >
                            {/* eslint-disable-next-line @next/next/no-img-element */}
                            <img className={styles.image} src={product.imageUrl} alt="" />
                            <span className={styles.title}>{product.title}</span>
                        </Link>
                    </li>
                ))}
            </ul>
        </motion.div>
    );
}
