import { PrezlyNavigation } from './PrezlyNavigation';
import { SonyNavigation } from './SonyNavigation';

interface Props {
    hasError?: boolean;
}

function Header({ hasError }: Props) {
    return (
        <header>
            <SonyNavigation />
            <PrezlyNavigation hasError={hasError} />
        </header>
    );
}

export default Header;
