import { useGetLinkLocaleSlug, useSearchSettings } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type MouseEvent, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useDevice } from '@/hooks/useDevice';
import { IconSearch } from '@/icons';
import { CUSTOM_MESSAGES } from '@/intl';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';
import { ButtonLink } from '@/ui';

import LanguagesDropdown from '../LanguagesDropdown';

import { Navigation } from './Navigation';

import styles from './PrezlyNavigation.module.scss';

const SearchWidget = dynamic(() => import('../SearchWidget'), { ssr: false });

interface Props {
    hasError?: boolean;
}

export function PrezlyNavigation({ hasError }: Props) {
    const { isTablet } = useDevice();
    const searchSettings = useSearchSettings();
    const getLinkLocaleSlug = useGetLinkLocaleSlug();
    const { formatMessage } = useIntl();
    const router = useRouter();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    const [isSearchWidgetShown, setIsSearchWidgetShown] = useState(false);

    function toggleSearchWidget(event: MouseEvent) {
        event.preventDefault();
        setIsSearchWidgetShown((o) => !o);
        fireComponentInteractionEvent({
            interactionType: 'in-page-click',
            componentType: 'presscentre_header',
            componentID: 'seu-press-centre-secondary-header',
            actionableItemValue: formatMessage(CUSTOM_MESSAGES.navigation.search),
        });
    }

    function closeSearchWidget() {
        return setIsSearchWidgetShown(false);
    }

    useEffect(() => {
        closeSearchWidget();
    }, [router.asPath]);

    return (
        <div className={styles.container}>
            <div className="container">
                <nav role="navigation" className={styles.nav}>
                    <Link
                        href="/"
                        locale={getLinkLocaleSlug()}
                        className={classNames(styles.siteLink)}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-secondary-header',
                                actionableItemValue: formatMessage(
                                    CUSTOM_MESSAGES.navigation.title,
                                ),
                            })
                        }
                    >
                        <h2 className={classNames(styles.title)}>
                            <FormattedMessage {...CUSTOM_MESSAGES.navigation.title} />
                        </h2>
                    </Link>

                    <Navigation />

                    <div className={styles.navRight}>
                        {searchSettings && (
                            <ButtonLink
                                href="/search"
                                localeCode={getLinkLocaleSlug()}
                                variation="navigation"
                                className={styles.searchToggle}
                                icon={IconSearch}
                                onClick={toggleSearchWidget}
                                aria-expanded={isSearchWidgetShown}
                                aria-controls="search-widget"
                                aria-label={formatMessage(CUSTOM_MESSAGES.navigation.search)}
                            >
                                {!isTablet && (
                                    <FormattedMessage {...CUSTOM_MESSAGES.navigation.search} />
                                )}
                            </ButtonLink>
                        )}

                        <LanguagesDropdown hasError={hasError} />

                        {searchSettings && (
                            <SearchWidget
                                dialogClassName={styles.mobileSearchWrapper}
                                isOpen={isSearchWidgetShown}
                                settings={searchSettings}
                                onClose={closeSearchWidget}
                            />
                        )}
                    </div>
                </nav>
            </div>
        </div>
    );
}
