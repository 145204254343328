import tinycolor from 'tinycolor2';

import { DEFAULT_THEME_SETTINGS } from '@/hooks';
import type { ThemeSettings } from 'types';

import styles from './Branding.module.scss';

const accentVariationFactors = {
    DARKEST: 18,
    DARKER: 12,
    DARK: 6,
    LIGHT: 6,
    LIGHTER: 12,
    LIGHTEST: 18,
};

export function getCssVariables(themeSettings: ThemeSettings) {
    const { accentColor, headerBackgroundColor, headerLinkColor } = themeSettings;

    // Use the default placeholder color if the header background color has not been changed
    const placeholderBackgroundColor =
        DEFAULT_THEME_SETTINGS.header_background_color.toLowerCase() ===
        headerBackgroundColor.toLowerCase()
            ? styles['default-placeholder-color']
            : headerBackgroundColor;

    const accentColorButtonText = tinycolor(accentColor).isLight()
        ? styles['dark-text-color']
        : styles['light-text-color'];

    return [
        `--prezly-font-family: "SST W20 Latin","Helvetica Neue",Helvetica,Arial,sans-serif`,
        `--prezly-font-weight-regular: 300`,
        `--prezly-font-weight-medium: 400`,
        `--prezly-font-weight-bold: 500`,
        `--prezly-accent-color: ${accentColor}`,
        `--prezly-accent-color-light: ${tinycolor(accentColor)
            .lighten(accentVariationFactors.LIGHT)
            .toHexString()}`,
        `--prezly-accent-color-lighter: ${tinycolor(accentColor)
            .lighten(accentVariationFactors.LIGHTER)
            .toHexString()}`,
        `--prezly-accent-color-lightest: ${tinycolor(accentColor)
            .lighten(accentVariationFactors.LIGHTEST)
            .toHexString()}`,
        `--prezly-accent-color-dark: ${tinycolor(accentColor)
            .darken(accentVariationFactors.DARK)
            .toHexString()}`,
        `--prezly-accent-color-darker: ${tinycolor(accentColor)
            .darken(accentVariationFactors.DARKER)
            .toHexString()}`,
        `--prezly-accent-color-darkest: ${tinycolor(accentColor)
            .darken(accentVariationFactors.DARKEST)
            .toHexString()}`,
        `--prezly-accent-color-button-text: ${accentColorButtonText}`,
        `--prezly-header-background-color: ${headerBackgroundColor}`,
        `--prezly-header-link-color: ${headerLinkColor}`,
        `--prezly-header-link-hover-color: ${
            headerLinkColor === DEFAULT_THEME_SETTINGS.header_link_color
                ? '#1f2937'
                : headerLinkColor
        }`,
        `--prezly-placeholder-background-color: ${placeholderBackgroundColor}`,
    ];
}
