import { useCurrentLocale } from '@prezly/theme-kit-nextjs';
import classNames from 'classnames';
import Link from 'next/link';

import { useFooterContext } from '@/contexts/footer';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';
import { getAbsoluteSonyUrl } from '@/utils';

import styles from './MetaSection.module.scss';

export function MetaSection() {
    const { metaContent } = useFooterContext();
    const locale = useCurrentLocale();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    if (!metaContent) {
        return null;
    }

    const { links, copyright, sonyGroupLink } = metaContent;

    return (
        <div className={styles.container}>
            <div className={classNames('container', styles.containerInner)}>
                <div className={styles.about}>
                    <ul className={styles.list}>
                        {links.map((link) => (
                            <li key={link.title}>
                                <Link
                                    className={styles.link}
                                    href={getAbsoluteSonyUrl(link.link, locale)}
                                    onClick={() =>
                                        fireComponentInteractionEvent({
                                            interactionType: 'navigation-click',
                                            componentType: 'presscentre_footer',
                                            componentID: 'seu-press-centre-footer',
                                            actionableItemValue: link.title,
                                        })
                                    }
                                >
                                    {link.title}
                                </Link>
                            </li>
                        ))}
                    </ul>
                    <p className={styles.copyright}>{copyright}</p>
                </div>
                <Link
                    className={styles.brandLink}
                    href={sonyGroupLink.link}
                    aria-label={sonyGroupLink.title}
                    onClick={() =>
                        fireComponentInteractionEvent({
                            interactionType: 'navigation-click',
                            componentType: 'presscentre_footer',
                            componentID: 'seu-press-centre-footer',
                            actionableItemValue: sonyGroupLink.title,
                        })
                    }
                >
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        className={styles.brandImage}
                        src={getAbsoluteSonyUrl(sonyGroupLink.imageUrl, locale)}
                        alt={sonyGroupLink.title}
                    />
                    {/* eslint-disable-next-line @next/next/no-img-element */}
                    <img
                        className={styles.brandImageHover}
                        src={getAbsoluteSonyUrl(sonyGroupLink.hoverImageUrl, locale)}
                        alt={sonyGroupLink.title}
                    />
                </Link>
            </div>
        </div>
    );
}
