import { translations } from '@prezly/theme-kit-intl';
import { useCompanyInformation, useCurrentLocale, useNewsroom } from '@prezly/theme-kit-nextjs';
import Image from '@prezly/uploadcare-image';
import classNames from 'classnames';
import Link from 'next/link';
import { useIntl } from 'react-intl';

import { SITE_URL_BY_LOCALE } from '@/constants';
import { useHeaderContext } from '@/contexts/header';
import { useDevice } from '@/hooks/useDevice';
import { SonyCartIcon, SonyHeartIcon, SonySearchIcon, SonyUserIcon } from '@/icons';
import { CUSTOM_MESSAGES } from '@/intl';
import { useSonyAnalytics } from '@/modules/SonyAnalytics';
import { ButtonLink } from '@/ui';

import { ProductNavigation } from './ProductNavigation';

import styles from './SonyNavigation.module.scss';

export function SonyNavigation() {
    const { newsroom_logo, display_name } = useNewsroom();
    const { name } = useCompanyInformation();
    const currentLocale = useCurrentLocale();
    const { formatMessage } = useIntl();
    const { isTablet } = useDevice();
    const { cartLabel, cartLink } = useHeaderContext();
    const { fireComponentInteractionEvent } = useSonyAnalytics();

    const newsroomName = name || display_name;
    const sonySiteUrl =
        SITE_URL_BY_LOCALE[currentLocale.toHyphenCode()] || SITE_URL_BY_LOCALE['en-UK'];

    function makeSonyUrl(path: string) {
        const url = new URL(sonySiteUrl);
        url.pathname = path;
        return url.toString();
    }

    return (
        <div className={styles.container}>
            <div className={classNames('container', styles.containerInner)}>
                <nav role="navigation" className={styles.nav}>
                    <Link
                        href={sonySiteUrl}
                        className={classNames(styles.newsroom, {
                            [styles.withoutLogo]: !newsroom_logo,
                        })}
                        onClick={() =>
                            fireComponentInteractionEvent({
                                interactionType: 'navigation-click',
                                componentType: 'presscentre_header',
                                componentID: 'seu-press-centre-main-header',
                                actionableItemValue: 'Sony Header Logo',
                            })
                        }
                    >
                        <h1
                            className={classNames(styles.title, {
                                [styles.hidden]: newsroom_logo,
                            })}
                        >
                            {newsroomName}
                        </h1>
                        {newsroom_logo && (
                            <Image
                                layout="fill"
                                objectFit="contain"
                                imageDetails={newsroom_logo}
                                alt={newsroomName}
                                className={styles.logo}
                            />
                        )}
                    </Link>

                    <ul className={styles.navigation}>
                        <li className={styles.navigationItem}>
                            <ButtonLink
                                className={styles.navigationButton}
                                href={makeSonyUrl('/mysony/login')}
                                icon={SonyUserIcon}
                                variation="navigation"
                                aria-label={formatMessage(CUSTOM_MESSAGES.sony.mySony)}
                                onClick={() =>
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_header',
                                        componentID: 'seu-press-centre-main-header',
                                        actionableItemValue: formatMessage(
                                            CUSTOM_MESSAGES.sony.mySony,
                                        ),
                                    })
                                }
                            >
                                {!isTablet && formatMessage(CUSTOM_MESSAGES.sony.mySony)}
                            </ButtonLink>
                        </li>
                        <li className={styles.navigationItem}>
                            <ButtonLink
                                className={styles.navigationButton}
                                href={makeSonyUrl('/my-favorites')}
                                icon={SonyHeartIcon}
                                variation="navigation"
                                aria-label={formatMessage(CUSTOM_MESSAGES.sony.myFavorites)}
                                onClick={() =>
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_header',
                                        componentID: 'seu-press-centre-main-header',
                                        actionableItemValue: formatMessage(
                                            CUSTOM_MESSAGES.sony.myFavorites,
                                        ),
                                    })
                                }
                            />
                        </li>
                        {cartLink && cartLabel && (
                            <li className={styles.navigationItem}>
                                <ButtonLink
                                    className={styles.navigationButton}
                                    href={cartLink}
                                    icon={SonyCartIcon}
                                    variation="navigation"
                                    aria-label={cartLabel}
                                    onClick={() =>
                                        fireComponentInteractionEvent({
                                            interactionType: 'navigation-click',
                                            componentType: 'presscentre_header',
                                            componentID: 'seu-press-centre-main-header',
                                            actionableItemValue: cartLabel,
                                        })
                                    }
                                />
                            </li>
                        )}
                        <li className={styles.navigationItem}>
                            <ButtonLink
                                className={styles.navigationButton}
                                href={makeSonyUrl('/store/search')}
                                icon={SonySearchIcon}
                                variation="navigation"
                                aria-label={formatMessage(translations.search.title)}
                                onClick={() =>
                                    fireComponentInteractionEvent({
                                        interactionType: 'navigation-click',
                                        componentType: 'presscentre_header',
                                        componentID: 'seu-press-centre-main-header',
                                        actionableItemValue: formatMessage(
                                            translations.search.title,
                                        ),
                                    })
                                }
                            />
                        </li>
                        <li className={styles.navigationItem}>
                            <ProductNavigation buttonClassName={styles.navigationButton} />
                        </li>
                    </ul>
                </nav>
            </div>
        </div>
    );
}
